.main-footer .footer-lower .footer-logo img {
    display: inline-block;
    max-width: 60%;
    max-height: 150px;
    padding: 15px;
}

.main-header .logo img {
    max-height: 100px;
    padding:5px;
}

@media only screen and (max-width: 1023px) {

    .main-header .logo {
        max-width: 100px;
    }

}
.big-title {

    padding: 10px;
    background-color: rgba(29, 23, 19, 0.41);
    text-align: center;
    border-radius: 25px;
}

.big-title .h2 {
    font-size: 38px;
}

#project-version-one .col-lg-4 .img-wrap .content-wrap:before {
    background: #002d2d;
}

.page-banner {
    padding: 180px 0px;

}

.page-banner .auto-container h1 {
    color:white;
    background-color: rgba(29, 23, 19, 0.41);
    border-radius: 25px;
    padding: 10px;
    float: right;
}

.blog-area {
    padding: 0px;
}

.participanti {
    padding-top: 30px;
}

.sidebar {
    margin-top: 0px;
}

.btn-style-one {
    background: #002d17;
}

.sponsors.style-two:before , .sponsors.style-two {
    background: #FFFFFF;
}
.sponsors.style-two {
    padding:20px 0px;
}

.owl-item li{
    vertical-align: auto;
}

.sponsor-logo-top {
    width:140px;
    float:right;
    padding-top:30px;
    padding-right: 20px;
}

.info {
    padding-bottom: 30px;
}

.default-section{
    padding:10px 0px 0px 0px;
}

.feature-listing {
    padding: 0px 0px 10px 0px;
}


.sec-text p{
    font-size:22px;
}

ul.inscriere li {
    list-style: initial;
}